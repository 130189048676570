import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SelectOrderingListComponent} from './select-ordering-list/select-ordering-list.component';
import {NgxSortableModule} from 'ngx-sortable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageUploaderComponent} from './image-uploader/image-uploader.component';
import {ImageCropperComponent} from './image-uploader/image-cropper.component';
import {ColorPickerComponent} from './color-picker/color-picker.component';
import {ChannelBreadcrumbComponent} from './channel-breadcrumb/channel-breadcrumb.component';
import {SelectConversionSegmentComponent} from './select-conversion-segment/select-conversion-segment.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {ChartComponent} from './chart/chart.component';
import {ChangeValueDecoratorComponent} from './change-value-decorator/change-value-decorator.component';
import {GranularityComponent} from './granularity/granularity.component';
import {TopChartsComponent} from './top-charts/top-charts.component';
import {ChartControlsComponent} from './chart-controls/chart-controls.component';
import {DatatableComponent} from './datatable/datatable.component';
import {MatTableModule} from '@angular/material/table';
import {ChartModule} from 'angular-highcharts';
import {CommonModule, NgOptimizedImage, TitleCasePipe} from '@angular/common';
import {PipesModule} from '../pipes/pipes.module';
import {BudgetOptimizationProgressbarComponent} from './budget-optimization-progressbar/budget-optimization-progressbar.component';
import {MatIconModule} from '@angular/material/icon';
import {PlanNameInputComponent} from './plan-name-input/plan-name-input.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {YesNoModalComponent} from './yes-no-modal/yes-no-modal.component';
import {MatInputModule} from '@angular/material/input';
import {QuantityComponent} from './quantity/quantity.component';
import {MediaChannelTableComponent} from './media-channel-table/media-channel-table.component';
import {CampaignOptimizationWizardComponent} from './campaign-optimization-wizard/campaign-optimization-wizard.component';
import {TimelineFlowComponent} from './timeline-flow/timeline-flow.component';
import {AddForecastMenuComponent} from './add-forecast-menu/add-forecast-menu.component';
import {StrippedChartComponent} from './stripped-chart/stripped-chart.component';
import {ForecastChartComponent} from './line-chart/forecast-chart.component';
import {FilterWrapperComponent} from './filter-wrapper/filter-wrapper.component';
import {MatFilterDirective} from './mat-table-filters/mat-filter.directive';
import {MediaChannelModalComponent} from './media-channel-modal/media-channel-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatMenuModule} from '@angular/material/menu';
import {FilterComponent} from './mat-table-filters/filter.component';
import {DateDialogComponent} from './mat-table-filters/date-filter/date-dialog.component';
import {TextDialogComponent} from './mat-table-filters/text-filter/text-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {EventsCrudProgressbarComponent} from './events-crud-progressbar/events-crud-progressbar.component';
import {ExternalPageComponent} from './external-page/external-page.component';
import {DirectivesModule} from '../directives/directives.module';
import {ColorSketchModule} from 'ngx-color/sketch';
import {ClientSelectionComponent} from './client-selection/client-selection.component';
import {MatSortModule} from '@angular/material/sort';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MspGeneralErrorComponent} from './msp-general-error/msp-general-error.component';
import {ModalInformationHighlightComponent} from './modal-information-highlight/modal-information-highlight.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {GranularValuesTableComponent} from './granular-values-table/granular-values-table.component';
import {BrandMetricsComponent} from '../pages/media-scenario-planner/calendar-plan-editor/brand-metrics/brand-metrics.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {TableComponent} from './table/table.component';
import {
    NgbAccordionModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbPaginationModule,
    NgbPopover,
    NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {LabelsComponent} from './labels/labels.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {InputFilterComponent} from './input-filter/input-filter.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ExplanationComponent} from './explanation/explanation.component';
import {MspChartOptionAttributionTypeComponent} from './msp-chart-option-attribution-type/msp-chart-option-attribution-type.component';
import {MatRadioModule} from '@angular/material/radio';
import {MspChartOptionXAxisComponent} from './msp-chart-option-xaxis/msp-chart-option-xaxis.component';
import {SelectKpiSegmentTableComponent} from '@/app/components/select-kpi-segment-table/select-kpi-segment-table.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import {PlotlyModule} from 'angular-plotly.js';
import {MspForecastTabComponent} from '../pages/media-scenario-planner/calendar-plan-editor/msp-forecast-tab/msp-forecast-tab.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {defineCustomElements} from '@revolist/revogrid/loader/index.es2017.js';
import {OppieInfoComponent} from '@/app/components/oppie-info/oppie-info.component';
import {NotificationPopupComponent} from '@/app/components/notification-popup/notification-popup.component';
import {CdkMenu, CdkMenuItem, CdkMenuTrigger} from '@angular/cdk/menu';
import {ModelConfigurationOverviewItemComponent} from '@/app/components/model-configuration-overview-item/model-configuration-overview-item.component';
import {InsightsUpdateHeaderComponent} from '@/app/components/insights-update-header/insights-update-header.component';
import {CrossCampaignOptimizationWizardComponent} from '@/app/components/cross-campaign-optimization-wizard/cross-campaign-optimization-wizard.component';
import {MatSelectModule} from '@angular/material/select';
import {InsightsUpdateDataOverviewComponent} from '@/app/components/insights-update-data-overview/insights-update-data-overview.component';
import {FireDashboardMultiOptionFilterComponent} from '@/app/components/fire-dashboard-multi-option-filter/fire-dashboard-multi-option-filter.component';
import {InsightsUpdateInnerHeaderComponent} from '@/app/components/insights-update-inner-header/insights-update-inner-header.component';
import {DatePickerComponent} from '@/app/components/date-picker/date-picker.component';
import {IujTooltipComponent} from '@/app/components/iuj-tooltip/iuj-tooltip.component';
import {IujModelFactorsComponent} from '@/app/components/iuj-model-factors/iuj-model-factors.component';
import {IujPublicationFiltersComponent} from '@/app/components/iuj-publication-filters/iuj-publication-filters.component';
import {RouterLink} from '@angular/router';
import {IujNonMediaComponent} from '@/app/components/iuj-non-media/iuj-non-media.component';
import {IujAttributionDisplayComponent} from '@/app/components/iuj-attribution-display/iuj-attribution-display.component';
import {IujMediaComponent} from '@/app/components/iuj-media/iuj-media.component';
import {AdvancedKpiSelectorComponent} from '@/app/components/advanced-kpi-selector/advanced-kpi-selector.component';
import {IujEventsEditorComponent} from '@/app/components/iuj-events-editor/iuj-events-editor.component';

defineCustomElements();
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
    declarations: [
        SelectOrderingListComponent,
        ImageUploaderComponent,
        ImageCropperComponent,
        ColorPickerComponent,
        ChannelBreadcrumbComponent,
        SelectConversionSegmentComponent,
        DatepickerComponent,
        DatePickerComponent,
        ChartComponent,
        ChangeValueDecoratorComponent,
        GranularityComponent,
        TopChartsComponent,
        ChartControlsComponent,
        DatatableComponent,
        BudgetOptimizationProgressbarComponent,
        PlanNameInputComponent,
        YesNoModalComponent,
        QuantityComponent,
        MediaChannelTableComponent,
        CampaignOptimizationWizardComponent,
        CrossCampaignOptimizationWizardComponent,
        TimelineFlowComponent,
        AddForecastMenuComponent,
        StrippedChartComponent,
        ForecastChartComponent,
        FilterWrapperComponent,
        MatFilterDirective,
        MediaChannelModalComponent,
        FilterComponent,
        DateDialogComponent,
        TextDialogComponent,
        EventsCrudProgressbarComponent,
        ExternalPageComponent,
        ClientSelectionComponent,
        MspGeneralErrorComponent,
        ModalInformationHighlightComponent,
        UnauthorizedComponent,
        GranularValuesTableComponent,
        BrandMetricsComponent,
        TableComponent,
        LabelsComponent,
        InputFilterComponent,
        ExplanationComponent,
        MspChartOptionAttributionTypeComponent,
        MspChartOptionXAxisComponent,
        SelectKpiSegmentTableComponent,
        MspForecastTabComponent,
        OppieInfoComponent,
        FireDashboardMultiOptionFilterComponent,
        NotificationPopupComponent,
        ModelConfigurationOverviewItemComponent,
        InsightsUpdateHeaderComponent,
        InsightsUpdateDataOverviewComponent,
        InsightsUpdateInnerHeaderComponent,
        IujTooltipComponent,
        IujModelFactorsComponent,
        IujPublicationFiltersComponent,
        IujNonMediaComponent,
        IujAttributionDisplayComponent,
        IujMediaComponent,
        AdvancedKpiSelectorComponent,
        IujEventsEditorComponent
    ],
    imports: [
        NgxSortableModule,
        FormsModule,
        MatTableModule,
        ChartModule,
        CommonModule,
        PlotlyModule,
        PipesModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        NgSelectModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatDatepickerModule,
        DirectivesModule,
        ColorSketchModule,
        MatSortModule,
        MatCardModule,
        MatTabsModule,
        MatStepperModule,
        MatTooltipModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatExpansionModule,
        NgbPaginationModule,
        DragDropModule,
        NgxSkeletonLoaderModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatOptionModule,
        MatCheckboxModule,
        MatRadioModule,
        MatButtonToggleModule,
        NgbTooltip,
        NgbPopover,
        NgOptimizedImage,
        CdkMenuTrigger,
        CdkMenu,
        CdkMenuItem,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownItem,
        NgbDropdownToggle,
        NgbAccordionModule,
        MatSelectModule,
        RouterLink,
    ],
    exports: [
        SelectOrderingListComponent,
        ImageCropperComponent,
        ColorPickerComponent,
        ChannelBreadcrumbComponent,
        SelectConversionSegmentComponent,
        DatepickerComponent,
        DatePickerComponent,
        ChartComponent,
        ChangeValueDecoratorComponent,
        GranularityComponent,
        TopChartsComponent,
        ChartControlsComponent,
        DatatableComponent,
        BudgetOptimizationProgressbarComponent,
        PlanNameInputComponent,
        YesNoModalComponent,
        QuantityComponent,
        MediaChannelTableComponent,
        CampaignOptimizationWizardComponent,
        CrossCampaignOptimizationWizardComponent,
        TimelineFlowComponent,
        AddForecastMenuComponent,
        StrippedChartComponent,
        ForecastChartComponent,
        FilterWrapperComponent,
        MatFilterDirective,
        MediaChannelModalComponent,
        FilterComponent,
        DateDialogComponent,
        TextDialogComponent,
        EventsCrudProgressbarComponent,
        ExternalPageComponent,
        ClientSelectionComponent,
        MspGeneralErrorComponent,
        ModalInformationHighlightComponent,
        GranularValuesTableComponent,
        BrandMetricsComponent,
        TableComponent,
        LabelsComponent,
        InputFilterComponent,
        ExplanationComponent,
        MspChartOptionAttributionTypeComponent,
        MspChartOptionXAxisComponent,
        SelectKpiSegmentTableComponent,
        MspForecastTabComponent,
        OppieInfoComponent,
        FireDashboardMultiOptionFilterComponent,
        NotificationPopupComponent,
        ModelConfigurationOverviewItemComponent,
        InsightsUpdateHeaderComponent,
        InsightsUpdateDataOverviewComponent,
        InsightsUpdateInnerHeaderComponent,
        IujTooltipComponent,
        IujModelFactorsComponent,
        IujPublicationFiltersComponent,
        IujNonMediaComponent,
        IujAttributionDisplayComponent,
        IujMediaComponent,
        AdvancedKpiSelectorComponent,
        IujEventsEditorComponent
    ],
    providers: [
        TitleCasePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {
}
